<template>
  <label class="toggle">
    <input :disabled="disabled" v-model="value" type="checkbox" class="toggle__checkbox" />
    <div class="toggle__container">
      <div class="toggle__ticker" :class="{
        'toggle__ticker--checked': !value,
        'gradient-green': variant === 'green',
        'gradient-orange--45': variant === 'orange',
      }"></div>
    </div>
  </label>
</template>

<script setup lang="ts">
import { computed } from "vue";
const props = defineProps({
  modelValue: {
    type: [Boolean, Number],
    default: false,
  },
  variant: {
    type: String,
    default: "green",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
const value = computed({
  get() {
    return !!props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});
</script>

<style lang="scss">
.toggle {
  // bg-[#3B404F] w-[35px] h-[20px] inline-flex cursor-pointer rounded-full p-[3px]
  background: var(--bg_secondary__300);
  width: 40px;
  height: 20px;
  display: inline-flex;
  cursor: pointer;
  border-radius: 9999px;

  &__checkbox {
    // invisible hidden
    visibility: hidden;
    display: none;
  }

  &__container {
    // relative flex items-center w-full h-full transition
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
  }

  &__ticker {
    // absolute top-0 w-auto h-full transition-all duration-300 rounded-full aspect-square
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    transition: all 0.3s ease-in-out;
    border-radius: 999px;
    aspect-ratio: 1/1;

    right: 0;

    background: var(--primary-700);

    &--checked {
      //right-[100%] translate-x-full
      right: 100%;
      transform: translateX(100%);
    }
  }
}
</style>
